import React, {useCallback, useState} from 'react';
import {RWebShare} from 'react-web-share';
import {TruvuButton} from '../../../components/button/TruvuButton';
import {useSnackbarContext} from '../../../context/SnackbarContext';
import {Typography} from '@mui/material';
import {useMutation} from 'react-relay-mutation';
import {TourCardActionsDialogMutation} from '../../../__generated__/TourCardActionsDialogMutation.graphql';
import graphql from 'babel-plugin-relay/macro';
import {
  DeleteForever,
  Edit,
  LinearScale,
  MovieCreation,
  QrCode,
  Share,
  Visibility,
} from '@mui/icons-material';

interface TourCardActionsDialogProps {
  onDeleteCallback: () => void;
  onCloseDialog: () => void;
  onEditDetails: () => void;
  onGetQRCode: () => void;
  onReelsDetails: () => void;
  title: string;
  id: string;
}

export function TourCardActions({
  onDeleteCallback,
  onCloseDialog,
  onEditDetails,
  onGetQRCode,
  onReelsDetails,
  title,
  id,
}: TourCardActionsDialogProps) {
  const {notify} = useSnackbarContext();
  const [requestDeleteConfirmation, setRequestDeleteConfirmation] = useState(
    false
  );
  const [deleting, setDeleting] = useState(false);
  const [tourDelete] = useMutation<TourCardActionsDialogMutation>(
    graphql`
      mutation TourCardActionsDialogMutation($tourId: ID!) {
        tourDelete(input: {tourId: $tourId}) {
          clientMutationId
        }
      }
    `
  );

  const handleDelete = useCallback(async () => {
    setDeleting(true);
    try {
      const response = await tourDelete({
        variables: {
          tourId: id,
        },
      });

      if (response) {
        setRequestDeleteConfirmation(false);
        onCloseDialog();
        onDeleteCallback();
      }
    } catch (e) {
      notify({message: 'Could not delete tour data', variant: 'error'});
    } finally {
      setDeleting(false);
    }
  }, [id, notify, onCloseDialog, onDeleteCallback, tourDelete]);

  const shareUrl = `${window.location.protocol}//${window.location.host}/playerext?tourId=${id}`;

  if (requestDeleteConfirmation) {
    return (
      <>
        <Typography variant="h6" fontWeight={500}>
          Are you sure you want to delete this tour?
        </Typography>
        <TruvuButton
          variant="secondary"
          onClick={() => setRequestDeleteConfirmation(false)}
          disabled={deleting}
        >
          Cancel
        </TruvuButton>
        <TruvuButton onClick={handleDelete} loading={deleting} variant="danger">
          {deleting ? 'Deleting...' : 'Delete'}
        </TruvuButton>
      </>
    );
  }

  return (
    <>
      <TruvuButton
        to={`/previewplayer?tourId=${id}`}
        variant="primary"
        startIcon={<Visibility fontSize={'inherit'} />}
        enableAuxNavigate
      >
        Preview
      </TruvuButton>
      <TruvuButton
        to={`/editplayer?tourId=${id}`}
        variant="secondary"
        startIcon={<LinearScale fontSize={'inherit'} />}
        enableAuxNavigate
      >
        Edit Fast Travel
      </TruvuButton>
      <TruvuButton
        onClick={onEditDetails}
        variant="secondary"
        startIcon={<Edit fontSize={'inherit'} />}
      >
        Edit Info
      </TruvuButton>
      <TruvuButton
        onClick={onGetQRCode}
        variant="secondary"
        startIcon={<QrCode fontSize={'inherit'} />}
      >
        Generate QR Code
      </TruvuButton>
      <TruvuButton
        onClick={onReelsDetails}
        variant="secondary"
        startIcon={<MovieCreation fontSize={'inherit'} />}
      >
        Instant Reels
      </TruvuButton>
      {/*<TruvuButton*/}
      {/*  onClick={onEditDetails}*/}
      {/*  variant="secondary"*/}
      {/*  startIcon={<Timeline fontSize={'inherit'} />}*/}
      {/*>*/}
      {/*  Tour Stats*/}
      {/*</TruvuButton>*/}
      <RWebShare
        data={{
          text: `Check out this Truvu Walkthrough: ${title}`,
          title: 'Share this Truvu Walkthrough',
          url: shareUrl,
        }}
        disableNative
      >
        <TruvuButton
          variant="secondary"
          startIcon={<Share fontSize={'inherit'} />}
        >
          Share
        </TruvuButton>
      </RWebShare>
      <TruvuButton
        onClick={() => setRequestDeleteConfirmation(true)}
        variant="danger"
        style={{marginTop: '30px'}}
        startIcon={<DeleteForever fontSize={'inherit'} />}
      >
        Delete tour
      </TruvuButton>
    </>
  );
}
