import React from 'react';
import {Stack} from '@mui/material';
import Typography from '@mui/material/Typography';
import {styled} from '@mui/material/styles';
import {addShouldNotForwardProps} from '../../../../utils/addShouldNotForwardProps';
import graphql from 'babel-plugin-relay/macro';
import {useFragment} from 'relay-hooks';
import {TourPreviewMarkers_tour$key} from '../../../../__generated__/TourPreviewMarkers_tour.graphql';
import {FrameStoreBuffer} from '../../types';
import gsap from 'gsap';
import {useSearchParam} from 'react-use';

const fragment = graphql`
  fragment TourPreviewMarkers_tour on Tour {
    markers {
      id
      name
      isFastTravel
      frameIndex
    }
  }
`;

export interface TourPreviewMarkersProps {
  tourRef: TourPreviewMarkers_tour$key;
  currentIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
  setFastTravellingStatus: React.Dispatch<
    React.SetStateAction<'idle' | 'forward' | 'backward'>
  >;
  buffer: FrameStoreBuffer;
}
/** The lower the value, the quicker it navigates */
const DURATION_RATIO = 0.002;

// Type definition for the message event
interface TruvuScrollMessage {
  type: 'truvu_scroll' | 'truvu_marker';
  frameIndex: number;
}
interface TruvuInputScrollMessage {
  type: unknown;
  frameIndex: unknown;
}

export const TourPreviewMarkers = React.memo(function TourPreviewMarkers({
  tourRef,
  buffer,
  setFastTravellingStatus,
  currentIndex,
  setCurrentIndex,
}: TourPreviewMarkersProps) {
  const hostEventEnabled = useSearchParam('hostEventEnabled');
  const liveViewing = useSearchParam('liveViewing');
  const tour = useFragment(fragment, tourRef);
  const [navigatingIndex, setNavigatingIndex] = React.useState<number | null>(
    null
  );
  const timeoutRef = React.useRef<NodeJS.Timeout>();

  const sortedMarkers = React.useMemo(
    () =>
      tour?.markers
        ?.filter(({isFastTravel}) => isFastTravel)
        .sort((a, b) => a.frameIndex - b.frameIndex) ?? [],
    [tour?.markers]
  );

  const onMarkerClick = React.useCallback(
    (frameIndex) => {
      if (timeoutRef.current != null) {
        clearTimeout(timeoutRef.current);
      }
      if (frameIndex > currentIndex) {
        setFastTravellingStatus('forward');
      } else {
        setFastTravellingStatus('backward');
      }
      timeoutRef.current = setTimeout(() => {
        setFastTravellingStatus('idle');
      }, 2500);
      setNavigatingIndex(frameIndex);
      const distance = currentIndex - frameIndex;
      gsap.to(frameIndex, {
        ease: 'power3.out', // easing function
        duration: Math.abs(distance) * DURATION_RATIO,
        onComplete: () => {
          setNavigatingIndex(null);
        },
        onUpdate: () => {
          const roundedVelocity = 0.033 / DURATION_RATIO;
          setCurrentIndex((prev) => {
            if (prev === frameIndex) {
              return prev;
            }
            const newVal =
              distance > 0 ? prev - roundedVelocity : prev + roundedVelocity;
            return distance > 0
              ? Math.max(newVal, frameIndex)
              : Math.min(newVal, frameIndex);
          });
        },
      });
    },
    [currentIndex, setCurrentIndex, setFastTravellingStatus]
  );

  React.useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      // Type guard to ensure the message has the correct structure
      const isValidTruvuScrollMessage = (
        data: TruvuInputScrollMessage
      ): data is TruvuScrollMessage => {
        return (
          data &&
          (data.type === 'truvu_scroll' || data.type === 'truvu_marker') &&
          typeof data.frameIndex === 'number'
        );
      };

      if (isValidTruvuScrollMessage(event.data)) {
        // Update currentIndex when receiving a valid truvu_scroll message
        if (event.data.type === 'truvu_marker') {
          onMarkerClick(event.data.frameIndex);
        }
        if (event.data.type === 'truvu_scroll') {
          setCurrentIndex(event.data.frameIndex);
        }
      }
    };
    if (hostEventEnabled === 'true' || liveViewing === 'true') {
      window.addEventListener('message', handleMessage);
    }

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [onMarkerClick, setCurrentIndex, hostEventEnabled, liveViewing]);

  if (sortedMarkers.length === 0) {
    return <Typography>No markers created yet</Typography>;
  }

  return (
    <Stack spacing={1}>
      {sortedMarkers?.map((marker) => {
        const disabled =
          marker.frameIndex >= buffer.storeLength || navigatingIndex != null;
        const isActive = Math.abs(currentIndex - marker.frameIndex) <= 0.5;
        return (
          <Marker
            key={marker.id}
            $isActive={isActive}
            $disabled={disabled}
            onClick={() => {
              if (!disabled && !isActive && marker.frameIndex != null) {
                onMarkerClick(marker.frameIndex);
              }
            }}
            noWrap
          >
            {marker.name}
          </Marker>
        );
      })}
    </Stack>
  );
});

const Marker = styled(Typography, {
  shouldForwardProp: addShouldNotForwardProps('$isActive', '$disabled'),
})<{$isActive: boolean; $disabled: boolean}>(
  ({theme, $isActive, $disabled}) => ({
    userSelect: 'none',
    cursor: $isActive || $disabled ? 'default' : 'pointer',
    textTransform: 'capitalize',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    webkitLineClamp: 1,
    webkitBoxOrient: 'vertical',
    whiteSpace: 'normal',
    fontWeight: $isActive ? 700 : 400,
    color: $isActive
      ? theme.palette.primary.main
      : $disabled
      ? theme.palette.text.secondary
      : 'inherit',
  })
);
