/* eslint-disable */
// @ts-nocheck
export function hasWebCodec() {
  const userAgent = navigator.userAgent.toLowerCase();
  // Check if browser is Firefox
  const isFirefox = userAgent.toLowerCase().includes('firefox');

  // Always return false for Firefox
  if (isFirefox) {
    return false;
  }

  // Check for Android and extract version
  const androidMatch = userAgent.match(/android\s([0-9.]*)/);
  if (androidMatch) {
    console.log('[androidMatch] ', androidMatch);
    const androidVersion = parseFloat(androidMatch[1]);
    // If Android version is less than 12, return false
    if (androidVersion < 12) {
      return false;
    }

    // Extract Chrome version for Android
    const chromeMatch = userAgent.match(/chrome\/([0-9.]*)/);
    if (chromeMatch) {
      console.log('[chromeMatch] ', chromeMatch);
      const chromeVersion = parseInt(chromeMatch[1]);
      // If Chrome version is less than 94, return false
      if (chromeVersion < 94) {
        return false;
      }
    }
  }

  // Original check for other browsers
  return (
    typeof VideoDecoder !== 'undefined' && typeof AudioDecoder !== 'undefined'
  );
}
