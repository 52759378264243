import React, {useEffect, useRef, useState, useCallback} from 'react';
import AgoraRTC, {
  IAgoraRTCClient,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
  ConnectionState,
  IAgoraRTCRemoteUser,
} from 'agora-rtc-sdk-ng';
import {
  Mic,
  MicOff,
  Videocam,
  VideocamOff,
  Call,
  CallEnd,
  Sync,
  SyncDisabled,
} from '@mui/icons-material';
import {ConfigService} from '../../../../utils';
import {useScrollHandler} from './useScrollHandler';

// Initialize RTC client outside component
const initializeRTCClient = () => {
  return AgoraRTC.createClient({
    mode: 'rtc',
    codec: 'vp8',
  });
};

interface ViewingSessionRequest {
  rtcToken: string;
  signalingToken: string;
  appId: string;
  viewingSessionUserId: number;
  expireTime: number;
}

interface TourWebRTCProps {
  tourId: string;
  viewingSessionRequest: ViewingSessionRequest;
  isHost?: boolean;
  drawerIsOpen: boolean;
  drawerRef: React.RefObject<HTMLDivElement>;
}

interface SyncMessage {
  type: 'sync';
  position: number;
}

export const TourWebRTC: React.FC<TourWebRTCProps> = React.memo(
  ({
    drawerRef,
    drawerIsOpen,
    tourId,
    viewingSessionRequest,
    isHost = false,
  }) => {
    const [isInCall, setIsInCall] = useState(false);
    const [isMicActive, setIsMicActive] = useState(true);
    const [isVideoActive, setIsVideoActive] = useState(true);
    const [isSyncActive, setIsSyncActive] = useState(false);
    const [remoteUsers, setRemoteUsers] = useState<IAgoraRTCRemoteUser[]>([]);
    const [connectionState, setConnectionState] = useState<ConnectionState>(
      'DISCONNECTED'
    );
    const [
      signalingConnection,
      setSignalingConnection,
    ] = useState<WebSocket | null>(null);
    const [tourDrawerWidth, setTourDrawerWidth] = useState(0);

    const rtcClient = useRef<IAgoraRTCClient | null>(initializeRTCClient());

    const localTracks = useRef<{
      audioTrack: IMicrophoneAudioTrack | null;
      videoTrack: ICameraVideoTrack | null;
    }>({
      audioTrack: null,
      videoTrack: null,
    });

    useEffect(() => {
      setTourDrawerWidth(drawerRef.current?.clientWidth ?? 0);
    }, [drawerRef]);

    // Set up RTC event listeners
    useEffect(() => {
      if (!rtcClient.current) return;

      const client = rtcClient.current;

      client.on('connection-state-change', setConnectionState);

      const handleUserPublished = async (
        user: IAgoraRTCRemoteUser,
        mediaType: 'audio' | 'video'
      ) => {
        await client.subscribe(user, mediaType);

        setRemoteUsers((prevUsers) => {
          if (!prevUsers.some((u) => u.uid === user.uid)) {
            return [...prevUsers, user];
          }
          return prevUsers;
        });

        if (mediaType === 'video' && user.videoTrack) {
          user.videoTrack.play(`remote-video-${user.uid}`);
        }
        if (mediaType === 'audio' && user.audioTrack) {
          user.audioTrack.play();
        }
      };

      const handleUserUnpublished = (user: IAgoraRTCRemoteUser) => {
        setRemoteUsers((prevUsers) =>
          prevUsers.filter((u) => u.uid !== user.uid)
        );
      };

      const handleUserLeft = (user: IAgoraRTCRemoteUser) => {
        setRemoteUsers((prevUsers) =>
          prevUsers.filter((u) => u.uid !== user.uid)
        );
      };

      const handleStreamMessage = (uid: number, data: Uint8Array) => {
        try {
          const message = JSON.parse(
            new TextDecoder().decode(data)
          ) as SyncMessage;
          if (message.type === 'sync') {
            console.log('Received scroll position:', message.position);
          }
        } catch (error) {
          console.error('Error parsing stream message:', error);
        }
      };

      client.on('user-published', handleUserPublished);
      client.on('user-unpublished', handleUserUnpublished);
      client.on('user-left', handleUserLeft);
      client.on('stream-message', handleStreamMessage);

      return () => {
        client.removeAllListeners();
      };
    }, []);

    useScrollHandler(signalingConnection, tourId);

    const startCall = async () => {
      try {
        if (!rtcClient.current) return;
        // Request permissions
        try {
          await navigator.mediaDevices.getUserMedia({audio: true, video: true});
        } catch (err) {
          console.log('Permission request failed, trying audio only:', err);
          try {
            await navigator.mediaDevices.getUserMedia({audio: true});
          } catch (audioErr) {
            console.log('Audio permission also failed:', audioErr);
          }
        }
        // Join RTC channel
        await rtcClient.current.join(
          viewingSessionRequest.appId,
          tourId,
          viewingSessionRequest.rtcToken,
          viewingSessionRequest.viewingSessionUserId
        );
        // Create and publish media tracks
        try {
          let audioTrack = null;
          let videoTrack = null;

          try {
            [
              audioTrack,
              videoTrack,
            ] = await AgoraRTC.createMicrophoneAndCameraTracks();
          } catch (err) {
            console.log('Failed to get both tracks, trying audio only:', err);
            try {
              audioTrack = await AgoraRTC.createMicrophoneAudioTrack();
            } catch (audioErr) {
              console.log('Failed to get audio track:', audioErr);
            }
          }

          localTracks.current = {
            audioTrack,
            videoTrack,
          };

          const tracksToPublish = [audioTrack, videoTrack].filter(
            (track) => track !== null
          );
          if (tracksToPublish.length > 0) {
            await rtcClient.current.publish(tracksToPublish);
          }

          if (videoTrack) {
            videoTrack.play('local-video');
          }

          setIsMicActive(!!audioTrack);
          setIsVideoActive(!!videoTrack);
          setIsInCall(true);
          if (!isHost) {
            await toggleSync();
          }
        } catch (error) {
          console.error('Failed to create media tracks:', error);
          setIsInCall(true);
          setIsMicActive(false);
          setIsVideoActive(false);
        }
      } catch (error) {
        console.error('Error starting call:', error);
      }
    };

    const leaveCall = useCallback(async () => {
      try {
        localTracks.current.audioTrack?.close();
        localTracks.current.videoTrack?.close();

        if (rtcClient.current) {
          await rtcClient.current.leave();
          rtcClient.current.removeAllListeners();
        }

        setIsInCall(false);
      } catch (error) {
        console.error('Error leaving call:', error);
      }
    }, []);

    useEffect(() => {
      return () => {
        leaveCall();
      };
    }, [leaveCall]);

    const toggleMic = useCallback(() => {
      if (localTracks.current.audioTrack) {
        localTracks.current.audioTrack.setEnabled(!isMicActive);
        setIsMicActive(!isMicActive);
      }
    }, [isMicActive]);

    const toggleVideo = useCallback(() => {
      if (localTracks.current.videoTrack) {
        localTracks.current.videoTrack.setEnabled(!isVideoActive);
        setIsVideoActive(!isVideoActive);
      }
    }, [isVideoActive]);

    const toggleSync = useCallback(async () => {
      const joinSignalingSubscription = async () => {
        if (isSyncActive) {
          // If active, disconnect
          signalingConnection?.close();
          setSignalingConnection(null);
          return;
        }

        // Create WebSocket connection using ConfigService
        const ws = new WebSocket(
          `${ConfigService.serverUri}/ws/tour-viewer-events?tourId=${tourId}`
        );

        ws.onopen = () => {
          console.log('WebSocket Connected');
          setSignalingConnection(ws);
          setIsSyncActive(true);
        };
        // Handles incoming ScrollEvent, and routes from scrolling to truvu_marker
        ws.onmessage = (event) => {
          try {
            const data = JSON.parse(event.data);
            if (data.type === 'truvu_scrolling') {
              // console.log({data})
              // Mark this as a remote event
              window.postMessage(
                {
                  type: 'truvu_marker',
                  frameIndex: data.data.frameIndex,
                },
                '*'
              );
            }
          } catch (error) {
            console.error('Error parsing WebSocket message:', error);
          }
        };

        ws.onerror = (error) => {
          console.error('WebSocket error:', error);
          setIsSyncActive(false);
        };

        ws.onclose = () => {
          console.log('WebSocket disconnected');
          setSignalingConnection(null);
          setIsSyncActive(false);
        };
      };

      await joinSignalingSubscription();
    }, [isSyncActive, tourId, signalingConnection]);

    // Rest of component render code remains the same
    if (!isInCall) {
      return (
        <button
          onClick={startCall}
          style={{
            position: 'absolute',
            bottom: '6rem',
            right: drawerIsOpen ? `calc(1rem + ${tourDrawerWidth}px)` : '1rem',
            zIndex: 50,
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
            padding: '1rem',
            backgroundColor: '#3b82f6',
            color: 'white',
            borderRadius: '9999px',
            boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Call sx={{fontSize: 20}} />
          <span>Talk to Agent</span>
        </button>
      );
    }

    return (
      <div
        style={{
          position: 'fixed',
          bottom: '6rem',
          right: drawerIsOpen ? `calc(1rem + ${tourDrawerWidth}px)` : '1rem',
          zIndex: 50,
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
          padding: '1rem',
          backgroundColor: '#3b82f6',
          color: 'white',
          borderRadius: '9999px',
          boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)',
        }}
      >
        <div className="bg-gray-900 rounded-lg overflow-hidden shadow-lg">
          <div
            className={`text-xs text-center py-1 ${
              connectionState === 'CONNECTED' ? 'bg-green-600' : 'bg-yellow-600'
            }`}
          >
            {connectionState}
          </div>

          <div className="relative w-64 h-48 bg-gray-800">
            {connectionState === 'CONNECTED' ? (
              remoteUsers.length === 0 ? (
                <div className="flex items-center justify-center w-full h-full text-gray-400">
                  {isHost ? 'Waiting on Viewer...' : 'Waiting on Agent...'}
                </div>
              ) : (
                remoteUsers.map((user: IAgoraRTCRemoteUser) => (
                  <div
                    key={user.uid}
                    id={`remote-video-container-${user.uid}`}
                    style={{
                      zIndex: 50,
                      display: 'flex',
                      backgroundColor: '#3b82f6',
                      color: 'white',
                    }}
                  >
                    <div
                      id={`remote-video-${user.uid}`}
                      style={{
                        width: '120px',
                        height: '60px',
                        backgroundColor: 'black',
                      }}
                    />
                  </div>
                ))
              )
            ) : (
              <div className="flex items-center justify-center w-full h-full text-gray-400">
                Not Connected
              </div>
            )}
          </div>

          {isInCall && (
            <div className="absolute bottom-2 right-2 w-20 h-16 bg-gray-800 rounded overflow-hidden">
              <div id="local-video" className="w-full h-full" />
            </div>
          )}

          <div className="p-2 bg-gray-800 flex justify-center gap-2">
            <button
              onClick={toggleMic}
              className="p-2 rounded-full hover:bg-gray-700 text-white"
            >
              {isMicActive ? (
                <Mic sx={{fontSize: 20}} />
              ) : (
                <MicOff sx={{fontSize: 20}} />
              )}
            </button>
            <button
              onClick={toggleVideo}
              className="p-2 rounded-full hover:bg-gray-700 text-white"
            >
              {isVideoActive ? (
                <Videocam sx={{fontSize: 20}} />
              ) : (
                <VideocamOff sx={{fontSize: 20}} />
              )}
            </button>
            <button
              onClick={toggleSync}
              className="p-2 rounded-full hover:bg-gray-700 text-white"
            >
              {isSyncActive ? (
                <Sync sx={{fontSize: 20}} />
              ) : (
                <SyncDisabled sx={{fontSize: 20}} />
              )}
            </button>
            <button
              onClick={leaveCall}
              className="p-2 rounded-full hover:bg-red-600 bg-red-500 text-white"
            >
              <CallEnd sx={{fontSize: 20}} />
            </button>
          </div>
        </div>
      </div>
    );
  }
);
