import * as React from 'react';
import {Route, Switch, RouteProps, Redirect} from 'react-router';
import {
  EmailActivationPage,
  EmbeddedExamplePage,
  ProfilePage,
  LoginPage,
  SignupPage,
  ToursPage,
  TeamPage,
  UploadPage,
  RequestPasswordResetPage,
  PasswordResetPage,
  TourPreviewPage,
  TourEditPage,
} from '../pages';
import {AuthContext} from '../context/AuthContext';
import * as Sentry from '@sentry/react';
import {TourReactivationPage} from '../pages/TourReactivationPage';
import {TourExt} from '../pages/tour/TourExt';
import {SubscriptionsPage} from '../pages/SubscriptionsPage';
import {TermsPage} from '../pages/TermsPage';
import {NotFoundPage} from './NotFoundPage';
import {GoogleRedirectPage} from '../pages/GoogleRedirectPage';
import {PrivacyPage} from '../pages/PrivacyPage';
import {LicensePage} from '../pages/LicensePage';

enum UserRole {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

type Page = {
  privateRoute?: boolean;
  requiredRole?: UserRole;
} & Omit<RouteProps, 'exact'>;

const protectedTourPlayerPaths = ['/previewplayer', '/editplayer'];

const Pages: Page[] = [
  {
    path: '/',
    component: ToursPage,
    privateRoute: true,
    requiredRole: UserRole.USER,
  },
  {
    path: '/team',
    component: TeamPage,
    privateRoute: true,
    requiredRole: UserRole.ADMIN,
  },
  {
    path: '/account',
    component: ProfilePage,
    privateRoute: true,
    requiredRole: UserRole.USER,
  },
  {
    path: '/account/activate',
    component: EmailActivationPage,
  },
  {
    path: '/tour/reactivate',
    component: TourReactivationPage,
    privateRoute: true,
    requiredRole: UserRole.USER,
  },
  {
    path: '/password/reset/request',
    component: RequestPasswordResetPage,
  },
  {
    path: '/password/reset',
    component: PasswordResetPage,
  },
  {
    path: '/upload',
    component: UploadPage,
    privateRoute: true,
    requiredRole: UserRole.USER,
  },
  {
    path: '/login',
    component: LoginPage,
  },
  {
    path: '/signup',
    component: SignupPage,
  },
  {
    path: '/signup/google',
    component: GoogleRedirectPage,
  },
  {
    path: '/profile/success',
    component: ProfilePage,
  },
  {
    path: '/profile/cancel',
    component: ProfilePage,
  },
  {
    path: '/embedded_example/:tourId',
    component: EmbeddedExamplePage,
  },
  {
    path: '/embedded_example',
    component: EmbeddedExamplePage,
  },
  {
    path: '/previewplayer',
    component: TourPreviewPage,
    privateRoute: true,
    requiredRole: UserRole.USER,
  },
  {
    path: '/editplayer',
    component: TourEditPage,
    privateRoute: true,
    requiredRole: UserRole.USER,
  },
  {
    path: '/playerext',
    component: TourExt,
  },
  {
    path: '/account/subscriptions',
    component: SubscriptionsPage,
    privateRoute: true,
    requiredRole: UserRole.USER,
  },
  {
    path: '/terms',
    component: TermsPage,
  },
  {
    path: '/privacy',
    component: PrivacyPage,
  },
  {
    path: '/licenses',
    component: LicensePage,
  },
  {
    path: '*',
    privateRoute: true,
    component: NotFoundPage,
  },
];

const SentryRoute = Sentry.withSentryRouting(Route);

export function Routes() {
  interface PrivateRouteProps extends RouteProps {
    requiredRole?: UserRole;
  }

  const PrivateRoute = ({
    component,
    location,
    requiredRole,
    ...props
  }: PrivateRouteProps) => {
    const {auth} = React.useContext(AuthContext);
    const Component = component && component;
    const RedirectComp = () => {
      if (
        location?.pathname != null &&
        location.search != null &&
        protectedTourPlayerPaths.includes(location?.pathname)
      ) {
        return (
          <Redirect
            to={{
              pathname: `/playerext`,
              search: location.search,
            }}
          />
        );
      }
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: {from: location},
          }}
        />
      );
    };
    return (
      <>
        <SentryRoute
          {...props}
          component={auth.authenticated ? Component : RedirectComp}
        />
      </>
    );
  };
  return (
    <>
      {/* <Reactour
        disableInteraction={false}
        steps={TutorialConfig()}
        accentColor={'#5374FC'}
        isOpen={tourOpen}
        rounded={5}
        showNavigation={false}
        showButtons={false}
        onRequestClose={() => {
          setTourOpen(false);
          handleCompleteTutorial();
        }}
      /> */}
      <Switch>
        {Pages.map(({privateRoute, ...props}, index) => {
          if (privateRoute) {
            return <PrivateRoute exact key={index} {...props} />;
          } else {
            return <SentryRoute exact key={index} {...props} />;
          }
        })}
      </Switch>
    </>
  );
}
