import React from 'react';
import {TruvuButton} from '../../../../components/button/TruvuButton';
import {TourMenu} from '../../components/TourMenu';

interface TourEditMenuProps {
  drawerIsOpen: boolean;
  tourTitle: string | undefined;
  tourId: string;
}

export function TourEditMenu({
  drawerIsOpen,
  tourId,
  tourTitle,
}: TourEditMenuProps) {
  return (
    <TourMenu
      tourId={tourId}
      tourTitle={tourTitle}
      drawerIsOpen={drawerIsOpen}
      mode="Edit"
    >
      <TruvuButton to={`/previewplayer?tourId=${tourId}`} variant="primary">
        Preview Tour
      </TruvuButton>
      <TruvuButton to="/" variant="danger">
        Save & Exit
      </TruvuButton>
    </TourMenu>
  );
}
