// useScrollHandler.ts
import {useEffect} from 'react';
import {ScrollHandler} from './WebRTCUtil';

export const useScrollHandler = (
  signalingConnection: WebSocket | null,
  tourId: string
) => {
  useEffect(() => {
    if (!signalingConnection) return;

    const scrollHandler = new ScrollHandler(signalingConnection, tourId);

    window.addEventListener('message', scrollHandler.handleScroll);

    return () => {
      window.removeEventListener('message', scrollHandler.handleScroll);
      scrollHandler.cleanup();
    };
  }, [tourId, signalingConnection]);
};
