import React from 'react';
import {TruvuButton} from '../../../../components/button/TruvuButton';
import {TourMenu} from '../../components/TourMenu';
import {Edit, ExitToApp} from '@mui/icons-material';

interface TourPreviewMenuProps {
  external: boolean;
  drawerIsOpen: boolean;
  title: string;
  tourId: string;
}

export function TourPreviewMenu({
  drawerIsOpen,
  external,
  title,
  tourId,
}: TourPreviewMenuProps) {
  return (
    <TourMenu
      tourId={tourId}
      tourTitle={title}
      drawerIsOpen={drawerIsOpen}
      mode={!external ? 'Preview' : null}
    >
      {!external && (
        <>
          <TruvuButton
            to={`/editplayer?tourId=${tourId}`}
            variant="secondary"
            startIcon={<Edit />}
          >
            Edit Tour
          </TruvuButton>
        </>
      )}
      <TruvuButton to="/" variant="danger" startIcon={<ExitToApp />}>
        {external ? 'Exit Tour' : 'Exit Preview'}
      </TruvuButton>
    </TourMenu>
  );
}
