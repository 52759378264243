import React, {useRef, useState} from 'react';
import {TruvuDialog, useTruvuDialog} from '../../../components/dialog';
import {TruvuDialogProps} from '../../../components/dialog';
import {TruvuButton} from '../../../components/button/TruvuButton';
import {Code, Link, QrCode} from '@mui/icons-material';
import {Stack} from '@mui/material';
import {TruvuIconButton} from '../../../components/button/TruvuIconButton';
import Typography from '@mui/material/Typography';
import {TourQRCode} from '../../tourLanding/components/TourQRCode';
import {getTourExternalLink} from '../../tourLanding/helpers/getTourExternalLink';
import {RWebShare} from 'react-web-share';

export function TourShareDialog({
  tourId,
  tourTitle,
  ...props
}: TruvuDialogProps & {tourId: string; tourTitle: string | undefined}) {
  const {
    openDialog: openQRDialog,
    onCloseDialog: onCloseQRDialog,
    onOpenDialog: onOpenQRDialog,
  } = useTruvuDialog();
  const shareUrl = getTourExternalLink(tourId);
  const [copyLinkStatus, setCopyLinkStatus] = useState<
    'idle' | 'copying' | 'copied' | 'error'
  >('idle');
  const [copyIframeStatus, setCopyIframeStatus] = useState<
    'idle' | 'copying' | 'copied' | 'error'
  >('idle');
  const copyTimeoutRef = useRef<NodeJS.Timeout>();

  const onCopyLink = async () => {
    setCopyLinkStatus('copying');
    try {
      await navigator.clipboard.writeText(shareUrl);
      setCopyLinkStatus('copied');
    } catch (error) {
      setCopyLinkStatus('error');
    } finally {
      copyTimeoutRef.current = setTimeout(() => {
        setCopyLinkStatus('idle');
      }, 6000);
    }
  };

  const onGetQRCode = () => {
    onOpenQRDialog();
    props.onClose();
  };

  const copyIframeToClipboard = async () => {
    const iframeUrl = `${getTourExternalLink(
      tourId
    )}&disabledControls=feedback&hostEventEnabled=true`;
    const iframeCode = `<iframe src="${iframeUrl}" allowfullscreen></iframe>`;
    setCopyIframeStatus('copying');
    try {
      await navigator.clipboard.writeText(iframeCode);
      setCopyIframeStatus('copied');
    } catch (error) {
      setCopyIframeStatus('error');
    } finally {
      copyTimeoutRef.current = setTimeout(() => {
        setCopyIframeStatus('idle');
      }, 6000);
    }
  };
  return (
    <>
      <TruvuDialog
        title="QR Code"
        open={openQRDialog}
        onClose={onCloseQRDialog}
        headingAction={
          <Stack direction={'row'} spacing={0.5}>
            <TruvuIconButton
              variant={copyLinkStatus === 'error' ? 'danger' : 'secondary'}
              size="large"
              onClick={onCopyLink}
              loading={copyLinkStatus === 'copying'}
            >
              {copyLinkStatus === 'idle' || copyLinkStatus === 'copying' ? (
                <Link fontSize={'inherit'} sx={{fontSize: '35px'}} />
              ) : (
                <Typography
                  fontSize="0.7rem"
                  variant="caption"
                  fontWeight={600}
                >
                  {copyLinkStatus === 'error' ? 'Failed' : 'Copied'}
                </Typography>
              )}
            </TruvuIconButton>
          </Stack>
        }
      >
        <TourQRCode tourId={tourId} />
      </TruvuDialog>

      <TruvuDialog {...props} title="Share Tour">
        <RWebShare
          data={{
            text: `Check out this Truvu Walkthrough: ${tourTitle}`,
            title: 'Share this Truvu Walkthrough',
            url: shareUrl,
          }}
          disableNative
        >
          <TruvuButton variant="secondary" startIcon={<Link />}>
            Share Link
          </TruvuButton>
        </RWebShare>
        <TruvuButton
          variant="secondary"
          startIcon={<QrCode />}
          onClick={onGetQRCode}
        >
          Generate QR Code
        </TruvuButton>
        <TruvuButton
          startIcon={<Code />}
          onClick={copyIframeToClipboard}
          loading={copyIframeStatus === 'copying'}
          variant={copyIframeStatus === 'error' ? 'danger' : 'secondary'}
          loadingText="Copying iFrame Code"
        >
          {copyIframeStatus === 'idle' || copyIframeStatus === 'copying'
            ? 'iFrame Code'
            : copyIframeStatus === 'error'
            ? 'Failed'
            : 'Copied iFrame Code'}
        </TruvuButton>
      </TruvuDialog>
    </>
  );
}
