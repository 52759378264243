/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TourPreview_tour = {
    readonly thumbnailURL: string;
    readonly title: string;
    readonly viewingSessionRequest: {
        readonly rtcToken: string;
        readonly signalingToken: string;
        readonly appId: string;
        readonly viewingSessionUserId: number;
        readonly expireTime: number;
    };
    readonly id: string;
    readonly " $fragmentRefs": FragmentRefs<"TourPreviewMarkers_tour" | "TourPlayerCanvas_tour">;
    readonly " $refType": "TourPreview_tour";
};
export type TourPreview_tour$data = TourPreview_tour;
export type TourPreview_tour$key = {
    readonly " $data"?: TourPreview_tour$data;
    readonly " $fragmentRefs": FragmentRefs<"TourPreview_tour">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./TourPreviewTodoRefetchQuery.graphql.ts'),
      "identifierField": "id"
    }
  },
  "name": "TourPreview_tour",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnailURL",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ViewingSessionRequest",
      "kind": "LinkedField",
      "name": "viewingSessionRequest",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rtcToken",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "signalingToken",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "appId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "viewingSessionUserId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "expireTime",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TourPreviewMarkers_tour"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TourPlayerCanvas_tour"
    }
  ],
  "type": "Tour",
  "abstractKey": null
};
(node as any).hash = 'fde3548892e75ba93a93ef79e623571a';
export default node;
