import React from 'react';
import CheckCircleRounded from '@mui/icons-material/CheckCircleRounded';
import CancelRounded from '@mui/icons-material/CancelRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import {TruvuDialog, UseTruvuDialogResposne} from './TruvuDialog';
import {Box, Stack, Typography} from '@mui/material';

interface TruvuMessageDialogProps extends Partial<UseTruvuDialogResposne> {
  title: React.ReactNode;
  variant: 'success' | 'error' | 'warning' | 'info';
  message?: React.ReactNode;
  actions?: React.ReactNode;
}

export function TruvuMessageDialog({
  title,
  variant,
  message,
  actions,
  ...props
}: TruvuMessageDialogProps) {
  let icon: React.ReactNode = null;

  if (variant === 'success') {
    icon = (
      <CheckCircleRounded
        color="primary"
        sx={{width: '90px', height: '90px'}}
      />
    );
  }
  if (variant === 'error') {
    icon = <CancelRounded color="error" sx={{width: '90px', height: '90px'}} />;
  }
  if (variant === 'warning') {
    icon = (
      <WarningRoundedIcon
        color="warning"
        sx={{width: '90px', height: '90px'}}
      />
    );
  }

  return (
    <TruvuDialog isOpen={false} {...props}>
      <Stack height="100%" maxHeight="90vh">
        {/* Fixed Header */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
          p={2}
        >
          <Typography variant="h1" {...props}>
            {title}
          </Typography>
          {icon}
        </Stack>

        {/* Scrollable Content */}
        {message != null && (
          <Box
            sx={{
              flex: 1,
              overflowY: 'auto',
              p: 2,
              // Custom scrollbar styling
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'rgba(0,0,0,0.05)',
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,0.2)',
                borderRadius: '4px',
                '&:hover': {
                  backgroundColor: 'rgba(0,0,0,0.3)',
                },
              },
            }}
          >
            {message}
          </Box>
        )}

        {/* Fixed Actions */}
        {actions != null && (
          <Stack
            spacing={2}
            justifyContent="flex-end"
            direction="column"
            p={2}
            sx={{
              borderTop: '1px solid',
              borderColor: 'divider',
              // Remove the backgroundColor property
              // Remove the boxShadow
            }}
          >
            {actions}
          </Stack>
        )}
      </Stack>
    </TruvuDialog>
  );
}
