import React, {useRef, useState} from 'react';
import QRCode from 'react-qr-code';
import {getTourExternalLink} from '../helpers/getTourExternalLink';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import {TruvuButton} from '../../../components/button/TruvuButton';
import Share from '@mui/icons-material/Share';

interface TourQRCodeProps {
  tourTitle?: string;
  tourId: string;
}

export function TourQRCode({tourTitle, tourId}: TourQRCodeProps) {
  const qrRef = useRef<SVGSVGElement>(null);
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadQRCode = async () => {
    if (!qrRef.current) return;

    try {
      setIsDownloading(true);

      const canvas = document.createElement('canvas');
      const svg = qrRef.current;
      const svgData = new XMLSerializer().serializeToString(svg);
      const svgBlob = new Blob([svgData], {
        type: 'image/svg+xml;charset=utf-8',
      });
      const DOMURL = window.URL || window.webkitURL || window;
      const url = DOMURL.createObjectURL(svgBlob);

      await new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx?.drawImage(img, 0, 0);
          DOMURL.revokeObjectURL(url);

          const pngUrl = canvas.toDataURL('image/png');
          const downloadLink = document.createElement('a');
          downloadLink.href = pngUrl;
          downloadLink.download = tourTitle
            ? `${tourTitle}.png`
            : `tour-${tourId}-qr-code.png`;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
          resolve(null);
        };
        img.onerror = reject;
        img.src = url;
      });
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <Stack alignItems="center" spacing={2}>
      <Box bgcolor="divider" p={3} borderRadius={1}>
        {/* @ts-expect-error Ref type not matching */}
        <QRCode value={getTourExternalLink(tourId)} ref={qrRef} />
      </Box>
      <TruvuButton
        startIcon={<Share />}
        variant="secondary"
        sx={{alignSelf: 'stretch'}}
        onClick={downloadQRCode}
        loading={isDownloading}
        loadingText="Downloading QR Code"
      >
        Share QR Code
      </TruvuButton>
    </Stack>
  );
}
