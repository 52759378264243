// WebRTCUtil.ts
export class ScrollHandler {
  private intervalId: NodeJS.Timeout | null = null;
  private timeoutId: NodeJS.Timeout | null = null;
  private latestEvent: MessageEvent | null = null;
  private signalingConnection: WebSocket;
  private tourId: string;

  constructor(signalingConnection: WebSocket, tourId: string) {
    this.signalingConnection = signalingConnection;
    this.tourId = tourId;
  }

  private sendScrollMessage(event: MessageEvent) {
    if (
      !event.data?.isRemote &&
      this.signalingConnection?.readyState === WebSocket.OPEN
    ) {
      try {
        this.signalingConnection.send(
          JSON.stringify({
            type: 'truvu_scrolling',
            tourId: this.tourId,
            data: {
              frameIndex: event.data.frameIndex,
            },
          })
        );
      } catch (err) {
        console.error('Error sending scroll message:', err);
      }
    }
  }

  private startInterval() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    if (this.latestEvent) {
      this.sendScrollMessage(this.latestEvent);
    }

    this.intervalId = setInterval(() => {
      if (this.latestEvent) {
        this.sendScrollMessage(this.latestEvent);
      }
    }, 10);
  }

  handleScroll = (event: MessageEvent) => {
    if (event.data?.type !== 'truvu_scrolling') return;

    this.latestEvent = event;

    if (!this.intervalId) {
      this.startInterval();
    }

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = setTimeout(() => {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
      if (this.latestEvent) {
        this.sendScrollMessage(this.latestEvent);
        this.latestEvent = null;
      }
    }, 200);
  };

  cleanup() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
      if (this.latestEvent) {
        this.sendScrollMessage(this.latestEvent);
        this.latestEvent = null;
      }
    }
  }
}
