import React, {useState} from 'react';
import {useRefetchable} from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';
import {FrameStoreBuffer} from '../../types';
import {TourPlayer} from '../../components/TourPlayer';
import {TourEdit_tour$key} from '../../../../__generated__/TourEdit_tour.graphql';
import {TourEditMenu} from './TourEditMenu';
import {TourEditDrawer} from './TourEditDrawer';
import {TOUR_DRAWER_WIDTH} from '../../components/TourDrawer';
import {useSearchParam} from 'react-use';
import {TourEditAddMarkerMenu} from './TourEditAddMarkerMenu';
import * as TruvuDialog from '../../../../components/dialog/TruvuDialog2';

const fragment = graphql`
  fragment TourEdit_tour on Tour
  @refetchable(queryName: "TourEditTodoRefetchQuery") {
    ...TourPlayerCanvas_tour
    ...TourEditDrawerMarkers_tour
    ...TourAddMarkers_tour
    title
    thumbnailURL
    markers {
      id
      name
      description
      scrollPosition
      frameIndex
    }
  }
`;
interface TourEditProps {
  tourRef: TourEdit_tour$key;
}

export function TourEdit({tourRef}: TourEditProps) {
  const {data: tour, refetch} = useRefetchable(fragment, tourRef);
  //
  // const tourGenerateAIMarker = (tourId: string) => {
  //   tourGenerateAIMarkerMutation({
  //     variables: {tourId: tourId},
  //   });
  // };

  const disabledControls = useSearchParam('disabledControls');
  const splitDisableControls = disabledControls?.toLowerCase()?.split(',');
  const addMarkersDialog = TruvuDialog.useTruvuDialog();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [buffer, setBuffer] = useState<FrameStoreBuffer>({
    totalImages: 0,
    storeLength: 0,
    fps: 0,
  });
  const [drawerIsOpen, setDrawerIsOpen] = useState(window.screen.width > 2500);
  const drawerRef = React.useRef<HTMLDivElement>(null);
  const tourCanvasRef = React.useRef<HTMLCanvasElement>(null);
  const [keepDrawerOpen, setKeepDrawerOpen] = React.useState(false);
  const [fastTravellingStatus, setFastTravellingStatus] = useState<
    'idle' | 'forward' | 'backward'
  >('idle');

  React.useEffect(() => {
    const onResize = () => {
      if (
        tourCanvasRef.current?.clientWidth != null &&
        window.innerWidth -
          parseInt(TOUR_DRAWER_WIDTH) -
          tourCanvasRef.current?.clientWidth >
          50
      ) {
        setKeepDrawerOpen(true);
      } else {
        setKeepDrawerOpen(false);
      }
    };

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [tourCanvasRef]);

  const handleGetCanvasWidth = React.useCallback((width: number) => {
    if (
      width > 0 &&
      window.innerWidth - width - parseInt(TOUR_DRAWER_WIDTH) > 50
    ) {
      setKeepDrawerOpen(true);
    }
  }, []);

  return (
    <>
      <div
        style={{
          overflow: 'hidden',
          position: 'absolute',
          inset: 0,
          left: 0,
          zIndex: 0,
          backgroundColor: '#758089',
          display: 'grid',
          placeContent: 'center',
        }}
      >
        {tour.thumbnailURL != null && (
          <img
            src={tour.thumbnailURL}
            alt={'thumbnail'}
            style={{
              width: '120vw',
              height: '120dvh',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              objectFit: 'cover',
              objectPosition: 'center',
              filter: 'blur(50px)',
              opacity: 0.9,
            }}
          />
        )}
      </div>
      <TourPlayer
        fastTravellingStatus={fastTravellingStatus}
        tourRef={tour}
        currentIndex={currentIndex}
        displayController={buffer.storeLength > 0}
        drawerIsOpen={drawerIsOpen || keepDrawerOpen}
        drawerRef={drawerRef}
        tourCanvasRef={tourCanvasRef}
        maxIndex={buffer.storeLength}
        setBuffer={setBuffer}
        setCurrentIndex={setCurrentIndex}
        getCanvasDimensions={handleGetCanvasWidth}
      />
      {!splitDisableControls?.includes('menu') && (
        <TourEditMenu
          drawerIsOpen={drawerIsOpen || keepDrawerOpen}
          tourTitle={tour.title}
          tourId={tour.id}
        />
      )}
      {!splitDisableControls?.includes('markers') && (
        <>
          <TourEditAddMarkerMenu
            refetch={() => refetch({})}
            addMarkersDialog={addMarkersDialog}
            currentIndex={currentIndex}
            tourRef={tour}
          />
          <TourEditDrawer
            keepDrawerOpen={keepDrawerOpen}
            drawerIsOpen={drawerIsOpen}
            setDrawerIsOpen={setDrawerIsOpen}
            tourRef={tour}
            buffer={buffer}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            drawerRef={drawerRef}
            onOpenAddMarkersDialog={addMarkersDialog.onOpen}
            setFastTravellingStatus={setFastTravellingStatus}
          />
        </>
      )}
    </>
  );
}
